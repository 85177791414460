<div class="body">
  <div class="main" *ngIf="!hasAgreed">
    <div style="object-fit:contain;">
      <img *ngIf="!isLoggedIn" style="height:300px;width:350px;padding-bottom:15px;padding-top:15px;" alt="logo" src="./assets/OPUS_v2.png" />
    </div>
    <form *ngIf="!isLoggedIn && !hasAgreed">
      <div style="display: flex; align-items: center;flex-wrap: nowrap;">
        <label for="studyId">Study ID:</label>
        <a href="/Files/User Guide.pdf" target="_blank" style="margin-left: 10px; white-space: nowrap;">User Guide</a>
      </div>
      <input type="text"
             id="studyId"
             name="studyId"
             [(ngModel)]="studyId"
             placeholder="Enter your study id" required>
      <div *ngIf="showErroMessage" style="color: red; font-weight: bold;">{{errorMessage}}</div>
      <div class="wrap">
        <button class="btn btn-primary button" (click)="isValidStudyId(studyId);" [disabled]="studyId === ''  || !studyId">
          Submit
        </button>
      </div>
    </form>
    <div *ngIf="isLoggedIn && !hasAgreed">
      <h2>Welcome to OPUS!</h2>
      <p>This chatbot is for research purposes only and should not be used in-place of an office visit or conversation with your healthcare provider.</p>
      <p>Please do not include any personal or identifying information (such as your name) within the chatbot.</p>
      <p>If you have an urgent question, please contact your healthcare provider.</p>
      <p>If you are experiencing a healthcare emergency please call 9-1-1.</p>
      <p>If you have any other questions, please email our study team:<a href="mailto:OPUSstudy@ccf.org"> OPUSstudy@ccf.org</a></p>
      <b>By clicking continue, I acknowledge that I have read the above and agree.</b>
      <div class="wrap">
        <button class="btn btn-primary button" (click)="hasAgreed=true;goToBot();">
          CONTINUE TO OPUS
        </button>
      </div>
    </div>
    <div *ngIf="!isLoggedIn && !hasAgreed">
      <p>
        Your unique Study ID can be found on your enrollment document and is the same for both entering the chatbot and for completing surveys.
      </p>
    </div>
  </div>
</div>



