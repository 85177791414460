import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudyService } from '../core/services/study.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isLoggedIn = false;
  hasAgreed = false;
  errorMessage = '';
  showErroMessage = false;
  studyId = '';

  constructor(
    private studyService: StudyService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(p => {
      if (p['studyId']) {
        this.isValidStudyId(p['studyId']);
      } else {
        this.isLoggedIn = false;
      }
    })
  };

  isValidStudyId = (studyId: string) => {
    this.studyService.isValidStudy(studyId).subscribe({
      next: (res) => {
        this.isLoggedIn = res.isSuccess;
        sessionStorage.setItem('isLoggedIn', 'true');
        if (res.isSuccess) {
          this.studyId = studyId;
          this.showErroMessage = false;
        } else {
          this.errorMessage = `${studyId} is not a valid study id.`
          this.showErroMessage = true;
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  };
  
  goToBot = () => {
    this.router.navigate(['/bot'], { queryParams: { studyId: this.studyId } });
  };
}
