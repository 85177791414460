import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface DirectLineDetails {
  directLineURL: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class CopilotServiceService {

  constructor( private http: HttpClient) { }

  getDirectLineDetails(tokenEndpointURL: string, apiVersion: string, additionalParams?: { [key: string]: string }): Observable<DirectLineDetails> {
    let params = new HttpParams().set('api-version', apiVersion);

    if (additionalParams) {
      for (const key in additionalParams) {
        if (additionalParams.hasOwnProperty(key)) {
          params = params.set(key, additionalParams[key]);
        }
      }
    }

    const regionalChannelSettingsURL = new URL('/powervirtualagents/regionalchannelsettings', tokenEndpointURL).toString();
    const regionalChannelSettings$ = this.http.get<{ channelUrlsById: { directline: string } }>(regionalChannelSettingsURL, { params }).pipe(
      map(response => response.channelUrlsById.directline),
      catchError(this.handleError)
    );

    const token$ = this.http.get<{ token: string }>(tokenEndpointURL, { params }).pipe(
      map(response => response.token),
      catchError(this.handleError)
    );

    return forkJoin({
      directLineURL: regionalChannelSettings$,
      token: token$
    }).pipe(
      catchError(this.handleError)
    ) as Observable<DirectLineDetails>;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
