import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class StudyService {

  apiUrl: string = '';

  constructor(private http: HttpClient,
             @Inject('BASE_URL') baseUrl: string) { this.apiUrl = `${baseUrl}api` }

  isValidStudy(studyId: string): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${this.apiUrl}/study`, { studyId: studyId }, {
      headers: new HttpHeaders({
        'Accept': 'application/json', 'Cache-Control': 'no-store'
      })
    }
    );
  }
}

interface IApiResponse {
  isSuccess: boolean;
  message: string;
  data: any;
}

